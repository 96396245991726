<template>
  <q-header elevated class="appbar">
    <div class="row no-wrap">
      <q-toolbar class="col-12 bg-primary">
        <q-btn
          flat
          round
          dense
          icon="menu"
          @click="$store.dispatch('toggleSidebar')"
        />
        <q-toolbar-title>
          <img
            :src="brandingLogo"
            height="32"
            class="cursor-pointer"
            @click="$router.push('/')"
          >
        </q-toolbar-title>
        <backoffice-environment-switch v-if="hasRole(['admin','backoffice'])" />
        <div v-if="showFeatureFlags" class="align-right q-px-lg-md">
          User is feature flagged for: <span v-for="(feature, index) in userFeatureFlags" :key="index"><strong>{{ feature }}</strong><span v-if="index < (userFeatureFlags.length - 1)">, </span></span>
        </div>
        <MSearch class="align-right row justify-end desktop-only" />
        <q-btn round class="q-ml-md shadow-1" :to="{ hash: '/profile' }" append>
          <q-avatar size="40px">
            <m-image :src="user.picture" />
            <div class="menuIcon shadow-4">
              <q-icon name="fas fa-cog" color="primary" />
            </div>
          </q-avatar>
        </q-btn>
      </q-toolbar>
    </div>
  </q-header>
</template>

<script type="text/javascript">
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import backofficeEnvironmentSwitch from '../backoffice-environment-switch'
export default {
  name: 'LayoutBasicAppbar',
  components: { backofficeEnvironmentSwitch },
  mixins: [authentication],
  computed: {
    ...mapGetters(['user', 'organisation', 'roles']),
    brandingLogo () {
      return (this.organisation && this.organisation.brand && this.organisation.brand.logo) ? this.organisation.brand.logo + `?${new Date().getTime()}` : require('assets/login-white.png')
    },
    isDevelopmentOrStaging () {
      return ['development', 'staging'].includes(process.env.NODE_ENV)
    },
    userFeatureFlags () {
      return this.roles.filter(r => r.includes('feature'))
    },
    showFeatureFlags () {
      return this.isDevelopmentOrStaging && this.userFeatureFlags.length > 0 && !this.roles.includes(['backoffice', 'admin'])
    }
  }
}
</script>

<style lang="stylus" scoped>
  .q-toolbar
    min-height: 64px
    transition: background-color 250ms
    will-change: background-color
  .q-input
    max-width 768px
  .menuIcon
    position absolute
    z-index 10
    background-color white
    border-radius 100%
    font-size .25em
    line-height .25em
    padding 4px
    bottom -2px
    right -2px
</style>
